/* eslint-disable react/prop-types */
import React from 'react';

import { Illustartion404, WatermarkBottom404, WatermarkTop404 } from '@components/icons';
import { Layout } from '@components/index';
import type { NextPage } from 'next';
import { type NextFont } from 'next/dist/compiled/@next/font';
import { DM_Sans } from 'next/font/google';
import Link from 'next/link';

const dmSans = DM_Sans({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  display: 'swap'
});
// eslint-disable-next-line react/prop-types
const Error: NextPage<{ inter: NextFont }> = ({ inter }) => {
  return (
    <Layout title="404">
    <section
      className={`relative h-screen grid place-content-center ${dmSans.className}`}
    >
      <WatermarkTop404 className="absolute top-0 right-0 lg:w-96 w-36 h-auto" />
      <WatermarkBottom404 className="absolute bottom-0 left-0 lg:w-96 w-36 h-auto" />
      <main className="flex flex-col items-center gap-6">
        <div className="flex flex-col gap-3 items-center">
          <h1 className="lg:text-3xl text-xl font-black">404</h1>
          <h3 className="lg:text-xl text-sm">
            Oops! we can’t seem to find the page you are looking for
          </h3>
        </div>
        <Illustartion404 className="w-3/4" />
        <Link href="/" className={`${inter.className} bg-darkPurple text-lightPurple px-6 py-4 font-medium rounded-md hover:bg-lightPurple hover:text-darkPurple transition-colors ease-in-out duration-300 lg:text-base text-sm`}>
          Back to home
        </Link>
      </main>
    </section>
    </Layout>
  );
};
export default Error;
